import styled from 'styled-components';
import { media } from '@app/styles/themes/constants';
import { BaseLayout } from '@app/components/common/BaseLayout/BaseLayout';
import { Menu } from 'antd';

export const LayoutMaster = styled(BaseLayout)`
  height: 100vh;
`;

export const LayoutMain = styled(BaseLayout)`
  margin-left: unset;
`;

export const MenuMain = styled(Menu)`
  .ant-menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .ant-menu-item .anticon {
    margin-top: 10px;
  }
  
  .ant-menu-item .ant-menu-title-content {
    margin-left: 0;
    font-size: 12px;
    font-weight: 800;
  }

`;
