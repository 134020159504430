import React, { useEffect, useState } from 'react';
import { Tag } from 'antd';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import formatNumber, { cancelOrder, closePosition, getPositionHistoryData, OpenOrderTableRow, OpenPositionTableRow, PositionHistoryTableRow } from '@app/api/table.api';
import { ColumnsType } from 'antd/lib/table';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { useMounted } from '@app/hooks/useMounted';

const PositionHistory: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [positionHistoryData, setPositionHistoryData] = useState<PositionHistoryTableRow[]>([]);
  const { isMounted } = useMounted();


  const fetchData = () => {
    setLoading(true);
    getPositionHistoryData().then((res) => {
      if (isMounted.current) {
        setPositionHistoryData(res);    
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, [isMounted]);
  
  const columns: ColumnsType<PositionHistoryTableRow> = [
    {
      title: () => (<div></div>),
      dataIndex: 'instrumentId',
      width: '30%',
      render: (text: string, record: { instrumentId: string; instrumentType: string; positionSide: string; tradeMode: string; leverage?: number }) => {
        return (
          <div>
            <BaseRow style={{padding: '5px 0px'}}>
              <BaseCol>
                <span style={{fontSize: '16px'}}>{text}</span>
              </BaseCol>
              <BaseCol style={{padding: '0px 5px'}}>
                <i style={{fontSize: '12px'}}>{record.instrumentType}</i>
              </BaseCol>
            </BaseRow>
            <BaseRow style={{padding: '5px 0px'}}>
              <BaseCol>
                <Tag color='gray'>{record.tradeMode}</Tag>
              </BaseCol>
              <BaseCol>
                <Tag color={record.positionSide.toLocaleLowerCase() === 'long' ? 'green' : 'red'}>{record.positionSide} {record.leverage ? record.leverage + 'x' : ''}</Tag>
              </BaseCol>
            </BaseRow>
          </div>
        );
      }
    },
    {
      title: () => (<div></div>),
      dataIndex: 'realizedPnl',
      width: '20%',
      render: (text: string, record: { realizedPnl: number; amount: number }) => {
        return (
          <div>
            <BaseRow style={{padding: '5px 0px'}}>
              <BaseCol>
                <span>Realized Pnl:</span><br/>
                <Tag color={record.realizedPnl >= 0 ? 'green' : 'red'}>${formatNumber(record.realizedPnl ?? 0, 2)}</Tag>
              </BaseCol>              
            </BaseRow>
            <BaseRow style={{padding: '5px 0px'}}>              
              <BaseCol>
                <span>Amount:</span><br/>
                <span>${formatNumber(record.amount, 2)}</span>
              </BaseCol>
            </BaseRow>
          </div>
        );
      }
    },
    {
      title: () => (<div></div>),
      dataIndex: 'createdTime',
      width: '20%',
      render: (text: string, record: { closedType: string; }) => {
        return (
          <div>
            <BaseRow style={{padding: '5px 0px'}}>
              <BaseCol>
                <span>Time:</span><br/>
                <span>{text}</span>
              </BaseCol>
            </BaseRow>
            <BaseRow style={{padding: '5px 0px'}}>
              <BaseCol>
                <span>Close Type:</span><br/>
                <span>{record.closedType}</span>
              </BaseCol>
            </BaseRow>
          </div>
        );
      }
    }
  ];
  return (
    <>
      <BaseTable
        loading={loading}
        columns={columns}
        dataSource={positionHistoryData}
        rowKey="positionId"
        showHeader={false}
        pagination={false}
        scroll={{ x: 720 }}
      />
    </>
  );
};

export default PositionHistory;
