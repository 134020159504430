import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { formatNumberWithCommas, getCurrencyPrice } from '@app/utils/utils';
import { Balance as IBalance, getBalance } from '@app/api/earnings.api';
import { CurrencyTypeEnum } from '@app/interfaces/interfaces';
import * as S from './Balance.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';

export const Balance: React.FC = () => {
  const [balance, setBalance] = useState<IBalance>({
    total: 0,
    available: 0
  });

  const userId = useAppSelector((state) => state.user.user?.id);

  useEffect(() => {
    userId && getBalance().then((res) => {
      setBalance(res)
    });
  }, [userId]);

  return (
    <BaseRow justify="start">
      <BaseCol style={{width: "80px"}}>
        <BaseRow>
          <BaseCol span={24}>
            <span>Bal</span>
          </BaseCol>
          <BaseCol span={24}>
            <span>
              {getCurrencyPrice(formatNumberWithCommas(balance.total), CurrencyTypeEnum['USD'])}
            </span>
          </BaseCol>          
        </BaseRow>
      </BaseCol>
      <BaseCol style={{width: "80px"}}>
        <BaseRow>          
          <BaseCol span={24}>
            <span>Avai</span>
          </BaseCol>
          <BaseCol span={24}>
            <span>
              {getCurrencyPrice(formatNumberWithCommas(balance.available), CurrencyTypeEnum['USD'])}
            </span>
          </BaseCol>
        </BaseRow>
      </BaseCol>
    </BaseRow>    
  );
};
