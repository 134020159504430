import React, { useEffect, useState } from 'react';
import { Tag } from 'antd';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import formatNumber, { cancelOrder, closeAllPosition, closePosition, OpenOrderTableRow, OpenPositionTableRow } from '@app/api/table.api';
import { ColumnsType } from 'antd/lib/table';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';

interface OpenPositionsProps {
  tableData: OpenPositionTableRow[];
  setTableData: (tableData: OpenPositionTableRow[]) => void;
}

const OpenPositions: React.FC<OpenPositionsProps> = ({ tableData, setTableData }) => {

  const handleClosePosition = (symbol: string, id: number) => {    
    closePosition(symbol, id).then((res) => {
      if (!res) {
        notificationController.error({ message: "something went wrong!" });
      }
      else{
        setTableData(tableData.filter((item) => item.positionId !== id));
      }
    }).catch((err) => {
      notificationController.error({ message: err.message });
    });

  };

  const handleCloseAllPosition = () => {    
    closeAllPosition().then((res) => {
      if (!res) {
        notificationController.error({ message: "something went wrong!" });
      }
      else{
        setTableData([]);
      }
    }).catch((err) => {
      notificationController.error({ message: err.message });
    });

  };

  const columns: ColumnsType<OpenPositionTableRow> = [
    {
      title: () => (<div></div>),
      dataIndex: 'instrumentId',
      width: '30%',
      render: (text: string, record: { instrumentId: string; instrumentType: string; positionSide: string; tradeMode: string; leverage?: number }) => {
        return (
          <div>
            <BaseRow style={{padding: '5px 0px'}}>
              <BaseCol>
                <span style={{fontSize: '16px'}}>{text}</span>
              </BaseCol>
              <BaseCol style={{padding: '0px 5px'}}>
                <i style={{fontSize: '12px'}}>{record.instrumentType}</i>
              </BaseCol>
            </BaseRow>
            <BaseRow style={{padding: '5px 0px'}}>
              <BaseCol>
                <Tag color='gray'>{record.tradeMode}</Tag>
              </BaseCol>
              <BaseCol>
                <Tag color={record.positionSide.toLocaleLowerCase() === 'long' ? 'green' : 'red'}>{record.positionSide} {record.leverage ? record.leverage + 'x' : ''}</Tag>
              </BaseCol>
            </BaseRow>
          </div>
        );
      }
    },
    {
      title: () => (<div></div>),
      dataIndex: 'realizedPnl',
      width: '15%',
      render: (text: string, record: { realizedPnl?: number; amount: number }) => {
        return (
          <div>
            <BaseRow style={{padding: '5px 0px'}}>
              <BaseCol>
                <span>Realized Pnl:</span><br/>
                <span>{text}</span>
              </BaseCol>              
            </BaseRow>
            <BaseRow style={{padding: '5px 0px'}}>              
              <BaseCol>
                <span>Amount:</span><br/>
                <span>${formatNumber(record.amount, 2)}</span>
              </BaseCol>
            </BaseRow>
          </div>
        );
      }
    },
    {
      title: () => (<div></div>),
      dataIndex: 'createdTime',
      width: '15%',
      render: (text: string) => {
        return (
          <BaseSpace>
            <BaseRow>
              <BaseCol>
                <span>Time:</span><br/>
                <span>{text}</span>
              </BaseCol>
            </BaseRow>
          </BaseSpace>
        );
      }
    },    
    {
      title: () => (<BaseButton type="primary" size="small" onClick={() => handleCloseAllPosition()}>Close All</BaseButton>),
      dataIndex: 'close',
      render: (text: string, record: {instrumentId: string, positionId: number}) => {
        return (
          <BaseSpace>
            <BaseButton type="dashed" size="small" onClick={() => handleClosePosition(record.instrumentId, record.positionId)}>Close</BaseButton>
          </BaseSpace>
        );
      },
    }
  ];
  return (
    <>
      <BaseTable
        columns={columns}
        dataSource={tableData}
        rowKey="positionId"
        showHeader={tableData.length > 0}
        pagination={false}
        scroll={{ x: 720 }}
      />
    </>
  );
};

export default OpenPositions;
