import React, { useEffect, useState } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import formatNumber, { AssetDto, convertAsset, getAssetData } from '@app/api/table.api';
import { ColumnsType } from 'antd/lib/table';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useMounted } from '@app/hooks/useMounted';
import { SyncOutlined } from '@ant-design/icons';
import { notificationController } from '@app/controllers/notificationController';

const Funding: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [converting, setConverting] = useState(false);

  const [assetData, setAssetData] = useState<AssetDto[]>([]);
  const [totalAsset, setTotalAsset] = useState<number>(0);

  const [convertToOKBDisabled, setConvertToOKBDisabled] = useState<boolean>(true);
  const { isMounted } = useMounted();


  const fetchData = () => {
    setLoading(true);
    getAssetData('funding').then((res) => {
      if (isMounted.current) {
        setAssetData(res.assets);  
        setTotalAsset(res.total);  
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, [isMounted]);
  
  const [selectedAssets, setSelectedAssets] = useState<AssetDto[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: AssetDto[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectedAssets(selectedRows);
      if(newSelectedRowKeys.length > 0)
      {
        setConvertToOKBDisabled(false);
      }
      else{
        setConvertToOKBDisabled(true);
      }
    },
    getCheckboxProps: (record: AssetDto) => ({
      name: record.asset,
    }),
  };

  const handleConvert = () => {    
    setConverting(true);
    setConvertToOKBDisabled(true);
    convertAsset(selectedAssets.map(x=> x.asset)).then((rs) => {
      if(!rs)
      {
        notificationController.error({ message: 'Something went wrong!' });
      }
    }).catch(()=>{
      notificationController.error({ message: 'Something went wrong!' });
    }).finally(()=>{
      setConverting(false);
      setConvertToOKBDisabled(false);
    })
  }

  const columns: ColumnsType<AssetDto> = [
    {
      title: () => (<div>Currency</div>),
      dataIndex: 'asset',
      width: '80%'      
    },
    {
      title: () => (<div>Available</div>),
      dataIndex: 'balance',
      width: '20%',
      render: (text: string, record: { balance: number; quantity: number }) => {
        return (
          <div>
            <BaseRow style={{padding: '5px 0px'}}>
              <BaseCol>
                ${formatNumber(record.balance, 2)}
              </BaseCol>              
            </BaseRow>
            <BaseRow style={{color: 'lightgray'}}>              
              <BaseCol>
                {formatNumber(record.quantity, 2)}
              </BaseCol>
            </BaseRow>
          </div>
        );
      }
    }
  ];
  return (
    <>
      <BaseRow justify="space-between" align="middle">
        <BaseCol>
          <span>Total: </span> <span style={{fontWeight: 'bold'}}>{formatNumber(totalAsset, 2)} USDT</span>
        </BaseCol>
        <BaseCol>
          <BaseButton disabled={convertToOKBDisabled} type='text' icon={<SyncOutlined />} onClick={()=> handleConvert()}>{converting ? "Converting..." : "Convert to OKB"}</BaseButton>
        </BaseCol>
      </BaseRow>
      <BaseTable
        rowSelection={rowSelection}
        loading={loading}
        columns={columns}
        dataSource={assetData}
        rowKey="asset"
        showHeader={true}
        pagination={false}
      />
    </>
  );
};

export default Funding;
