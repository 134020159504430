import React, { useState, useEffect, useCallback } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useMounted } from '@app/hooks/useMounted';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { doSaveGeneralSetting, setBotStatus } from '@app/store/slices/userSlice';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { getGeneralSetting, resetBot, startStopBot } from '@app/api/user.api';
import { mergeBy } from '@app/utils/utils';
import { InputNumber, Tabs, TabsProps } from 'antd';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { SyncOutlined } from '@ant-design/icons';
import TabPane from 'antd/lib/tabs/TabPane';
import Funding from '@app/components/tables/Assets/Funding';
import Trading from '@app/components/tables/Assets/Trading';
import Transfer from '@app/components/tables/Assets/Transfer';
interface FieldData {
  name: string | number;
  //  
  value?: any;
}
const AssetPage: React.FC = () => {
  const [activeKey, setActiveKey] = useState('1');
  const onChange = (key:string) => {
    setActiveKey(key);
  };
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'FUNDING',
      children: <Funding />,
    },
    {
      key: '2',
      label: 'TRADING',
      children: <Trading />,
    },
    {
      key: '3',
      label: 'TRANSFER',
      children: <Transfer />,
    },
  ];
  return (
    <>
      <PageTitle>Assets</PageTitle>
      <Tabs activeKey={activeKey} onChange={onChange} items={items} destroyInactiveTabPane>          
      </Tabs>
    </>
  );
};

export default AssetPage;
